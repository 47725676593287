/*
 * @Author: bluelife
 * @Email: thebulelife@163.com
 * @Date: 2022-12-10 01:11:36
 * @LastEditTime: 2022-12-10 01:53:51
 * @LastEditors: bluelife
 * @Description:
 * @FilePath: /h5.changogo.com/src/router/index.js
 * 叩首问路，码梦为生。
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/graph",
    name: "谱系",
    component: () => import("../views/Graph.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
