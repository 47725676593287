/*
 * @Author: bluelife
 * @Email: thebulelife@163.com
 * @Date: 2022-12-10 01:11:36
 * @LastEditTime: 2022-12-10 11:01:49
 * @LastEditors: bluelife
 * @Description:
 * @FilePath: /h5.changogo.com/src/main.js
 * 叩首问路，码梦为生。
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
Vue.prototype.$window = window;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
